import './NewTest.css'
import Table from "../../../components/utilities/table/Table";
import TableHead from "../../../components/utilities/table/TableHead";
import TableBody from "../../../components/utilities/table/TableBody";
import TransparentPageLayout from "../../../layout/page-layout/TransparentPageLayout";
import FlexBox from "../../../components/utilities/FlexBox";
import {Button} from "react-bootstrap";
import InputText, {TopInputLabel} from "../../../components/utilities/InputText";
import {useContext, useEffect, useMemo, useState} from "react";
import TableRow from "../../../components/utilities/table/TableRow";
import deleteQuestion from "../../../assets/test-new/deleteQuestion.svg"
import axios from "axios";
import HighlightText from "../../../components/highlight/HighlightText";
import showFileIcon from '../../../assets/showFile.png';
import helpIcon from '../../../assets/Q_white.png';
import watchIcon from '../../../assets/arrow_white.png';

import {
    API_URL_PREFIX, APP_URL_PREFIX, DELIMETER_BETWEEN_QUESTIONS
} from "../../../utilities/Constants";

import {
    b64DecodeUnicode,
    extractContent, generateDescriptionFromQuestions,
    getDifficultLevel
} from "../../../utilities/Utilities";
import Editor from "../../../components/ace-editor/Editor";
import {
    ArrowLeft,
    ArrowLeftCircle, ArrowRight,
    ArrowRightCircle,
    CaretRightFill,
    CheckCircleFill,
    ClipboardMinus,
    ClipboardPlus, GripHorizontal, GripVertical, ListCheck, ListNested,
    Plus,
    PlusCircle,
    X
} from "react-bootstrap-icons";
import {
    CustomErrorToast,
    CustomInfoToast,
    CustomLoadingToast,
    CustomToastContainer,
    updateCustomErrorToast,
    updateCustomSuccessToast
} from "../../../components/utilities/CustomToast";
import {Link, useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/utilities/Loader";
import CompanyTeamContext from "../../../CompanyTeamContext";
import Checkbox from "../../../components/utilities/Checkbox";
import Tooltip from "../../../components/utilities/Tooltip";
import {defaultContentValue, defaultSubjectValue, PreferencesBody} from "../../general/Preferences";
import * as PropTypes from "prop-types";
import ModalBackground from "../../../components/utilities/modal/ModalBackground";
import ModalHeader from "../../../components/utilities/modal/ModalHeader";
import ModalImage from 'react-modal-image';
import ItemsList from "../../../components/utilities/items-list/ItemsList";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function saveByteArray(reportName, resultByte, cQuestion) {
    if (cQuestion.bucketFileUrl && cQuestion.type == 28) {
        window.open(cQuestion.bucketFileUrl)
        return;
    }
    var blob = new Blob([resultByte]);
    var fileName = "specification.docx";
    if (cQuestion.hasOwnProperty('secondFileType') && cQuestion.secondFileType == 'zip') {
        fileName = "specification.zip";
    }
    if (cQuestion.hasOwnProperty('secondFileType') && (cQuestion.secondFileType == 'xls' || cQuestion.secondFileType == 'xlsx')) {
        fileName = "specification." + cQuestion.secondFileType;
    }
    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
        window.navigator.msSaveBlob(blob, fileName);
    } else {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean-up after download
    }
}


const MAX_WIDTH = "1000px"
const NO_OPTION = "dis"

const TestBuilder = () => {

    const [test, setTest] = useState({})
    const [selectedQuestions, setSelectedQuestions] = useState([])
    const [loaderArea, setLoaderArea] = useState(true)
    const [loaderTest, setLoaderTest] = useState(false)
    const {id} = useParams();

    const {key, name} = useContext(CompanyTeamContext) || {}

    // for the custom invite
    const [defaultSubject, setDefaultSubject] = useState(true)
    const [subject, setSubject] = useState(defaultSubjectValue)

    const [defaultContent, setDefaultContent] = useState(true)
    const [content, setContent] = useState(defaultContentValue)
    const company = useContext(CompanyTeamContext)
    const [progLangAltMap, setProgLangAltMap] = useState({});

    const [notifyList, setNotifyList] = useState([])

    useEffect(() => {
        if (!id) {
            if (company) {
                document.title = "New Test - " + company.basicTitle
            }
            // turn on the enable screenshot on new tests
            setTest({...test, usePrintScreen: true, questionsWeights: {}})

            return;
        }
        setLoaderTest(true)
        axios.get(API_URL_PREFIX + "/GetCompanyLiteTest.html?id=" + id)
            .then(res => {
                if (res.data) {
                    if (company) {
                        document.title = res.data.name + " - " + company.basicTitle
                    }
                    let qIds = res.data.questionsIds.value || ""
                    let selectedQuestions = res.data.quizQuestions;

                    // parse the alternative languages
                    if (res.data.progLangAlternatives && res.data.progLangAlternatives.value) {
                        let progData = res.data.progLangAlternatives.value;
                        let newProgMap = {};
                        if (progData && progData.length > 0) {
                            let lines = progData.split("__NEWLINE__");
                            for (let i = 0; i < lines.length; i++) {
                                let line = lines[i];
                                let keyVal = line.split("__VAL__");
                                newProgMap[keyVal[0]] = keyVal[1];
                            }
                        }
                        setProgLangAltMap(newProgMap)
                    }
                    setTest({
                        ...res.data,
                        questionsIds: qIds.split(DELIMETER_BETWEEN_QUESTIONS),
                        selectedQuestions: selectedQuestions
                    })

                    // init custom invite
                    if (res.data && res.data.customInviteContent?.value) {
                        setTimeout(() => {
                            setContent(res.data.customInviteContent.value)
                        }, 150)
                        setDefaultContent(false)
                    } else {
                        setDefaultContent(true)
                    }
                    if (res.data.notifyList) {
                        setNotifyList(res.data.notifyList.split(","))
                    }

                    if (res.data && res.data.customInviteSubject?.value) {
                        setSubject(res.data.customInviteSubject.value)
                        setDefaultSubject(false)
                    } else {
                        setDefaultSubject(true)
                    }
                }
            })
            .catch(e => {
                console.log(e);
            })
            .finally(e => {
                setLoaderTest(false)
            })

        return () => {
            if (company) {
                document.title = company.basicTitle
            }
        }
    }, [id, company])

    return (<TransparentPageLayout>
        <Loader loader={loaderArea || loaderTest}/>
        <div style={{height: "100%"}}>
            <Header test={test} setTest={setTest} content={content} setContent={setContent} subject={subject}
                    setSubject={setSubject} setDefaultSubject={setDefaultSubject} setDefaultContent={setDefaultContent}
                    defaultSubject={defaultSubject} defaultContent={defaultContent} notifyList={notifyList} setNotifyList={setNotifyList} selectedQuestions={selectedQuestions} />
            <br/>
            <Body progLangAltMap={progLangAltMap} setProgLangAltMap={setProgLangAltMap} companyKey={key} companyName={name} test={test} setTest={setTest} setLoaderArea={setLoaderArea} selectedQuestions={selectedQuestions} setSelectedQuestions={setSelectedQuestions}/>
            <Create notifyList={notifyList} progLangAltMap={progLangAltMap} test={test} defaultSubject={defaultSubject} subject={subject} defaultContent={defaultContent} content={content}/>
        </div>
    </TransparentPageLayout>)

}
export default TestBuilder

function Header({test, setTest, defaultSubject, setDefaultSubject, subject, setSubject, defaultContent, setDefaultContent, content, setContent, notifyList, setNotifyList, selectedQuestions}) {
    const [showSupervised, setShowSupervised] = useState(false)
    const [showPreferences, setShowPreferences] = useState(false)
    const [showTestPreferences, setShowTestPreferences] = useState(false)
    return (<div className={"container hard-shadow"} style={{
        background: "#4760F1", maxWidth: MAX_WIDTH, height: "170px", padding: "20px"
    }}>
        {showSupervised && <SupervisedSettings setTest={setTest} test={test} setShow={setShowSupervised}/>}
        {showPreferences && <PreferencesSettings content={content} setContent={setContent} subject={subject}
                                                 setSubject={setSubject} setDefaultSubject={setDefaultSubject} setDefaultContent={setDefaultContent}
                                                 defaultSubject={defaultSubject} defaultContent={defaultContent} setTest={setTest} test={test} setShow={setShowPreferences}/>}
        {showTestPreferences && <TestPreferencesSettings setShow={setShowTestPreferences} test={test} setTest={setTest} selectedQuestions={selectedQuestions} />}
        <FlexBox justify={"space-between"}>
            <div style={{
                lineHeight: "20px", color: "#fff", fontWeight: "800", fontSize: "20px"
            }}>Test Builder
            </div>
            <div>
                <Button onClick={() => setShowSupervised(true)} variant={"light"}>Supervised</Button> &nbsp;
                <Button onClick={() => setShowPreferences(true)} variant={"light"}>Mail Preferences</Button> &nbsp;
                <Button disabled={!selectedQuestions} onClick={() => setShowTestPreferences(true)} variant={"light"}>Test Preferences</Button>
            </div>
        </FlexBox>
        <br/>

        <FlexBox justify={"start"}>
            <InputText className={"header-field"} mode={"white"} label={"Test Name"} field={test.name}
                       setField={e => setTest({...test, name: e.target.value})}/>

            <InputText className={"header-field"} mode={"white"} label={"Test Time (Minutes)"} field={test.timePerQuiz || 0}
                       setField={e => setTest({...test, timePerQuiz: Math.round(((e.target.value < 0) ? 0 : (e.target.value)) || 0)})} type={"number"}/>

            <InputText className={"header-field"} mode={"white"} label={"Expiration (Days)"} field={test.expirationPeriod || 0}
                       setField={e => setTest({...test, expirationPeriod: Math.round(((e.target.value < 0) ? 0 : (e.target.value)) || 0)})} type={"number"}/>

            <TopInputLabel className={"header-field"} mode={"white"} label={"Notify List"}>
                <ItemsList listData={notifyList} setListData={setNotifyList}/>
            </TopInputLabel>
                <div style={{ marginLeft: "auto", marginTop: "30px", display: "flex", justifyContent: "flex-end" }}>                
                <a href="https://support.lugo-test.com/support/solutions/articles/154000106012-creating-a-test" target="_blank" style={{ textDecoration: "none" }}>
                    <img src={helpIcon} height="30" width="30" alt="Support Link" />
                </a>
                <a href="https://www.youtube.com/watch?v=B1F5UKDI4gI" target="_blank" style={{ textDecoration: "none", marginLeft: "10px" }}>
                    <img src={watchIcon} height="30" width="30" alt="YouTube Link" />
                </a>
            </div>    
        </FlexBox>
    </div>);
}

const SingleColTable = ({langs, active, setActive, type}) => {
    if (!langs || langs.length == 0) {
        return <div>No Languages Available</div>
    }
    return <div style={{width: "100%", padding: "0px 15px", height: "300px", overflowY: "auto"}}>
        {langs.map(l => <SingleCol name={l} setActive={setActive} active={active} type={type}/>)}
    </div>
}

const SingleCol = ({name, active, setActive, type}) => {
    let pl = name
    let plFilteredName = pl;
    if (pl == "Selenium Java" && type != 18 && type != 20) {
        plFilteredName = "Ruby";
    }
    else if(pl == "CSharp"){
        plFilteredName = "C#";
    }
    else if(pl == "Cpp"){
        plFilteredName = "C++"
    }
    return <div onClick={()=>setActive(name)} className={"single-col "+(active == name && "active-col")}>{plFilteredName}</div>
}

const ProgAltModal = ({progLangAltMap, setProgLangAltMap, setShow, currentQuestion}) => {

    const [available, setAvailable] = useState([])
    const [selected, setSelected] = useState([])

    const [active, setActive] = useState()

    const move = (setSrc, setDest) => {
        if (!active) return;
        setSrc(src => src.filter(e => e != active));
        setDest(src => [...src, active]);
        setActive(null)
    }

    const saveAlts = () => {
        let progLangAltMapTemp = {...progLangAltMap}
        for (let i = 0; i < selected.length; i++) {
            let plName = selected[i];

            progLangAltMapTemp[currentQuestion.key + "__KEY__" + plName] = currentQuestion.alternativesPL[plName];
            let altPL = currentQuestion.categoryName;
            let altQId = currentQuestion.alternativesPL[plName];
            progLangAltMapTemp[altQId + "__KEY__" + altPL] = currentQuestion.key;
        }
        for (let i = 0; i < available.length; i++) {
            let plName = available[i];
            delete progLangAltMapTemp[currentQuestion.key + "__KEY__" + plName];
            let altPL = currentQuestion.categoryName;
            let altQId = currentQuestion.alternativesPL[plName];
            delete progLangAltMapTemp[altQId + "__KEY__" + altPL];
        }

        setProgLangAltMap(progLangAltMapTemp)
        setShow(false)
    }

    useEffect(() => {
        // parse the question alts
        let availableTemp = new Array();
        let selectedTemp = new Array();

        for (let qkey in progLangAltMap) {
            let kval = qkey.split("__KEY__");

            if (kval[0] == currentQuestion.key) {
                if (kval[1] != currentQuestion.categoryName) {
                    selectedTemp.push(kval[1]);
                }
            }
        }
        for (let pl in currentQuestion.alternativesPL) {
            if (!selectedTemp.includes(pl)) {
                if (pl != currentQuestion.categoryName) {
                    availableTemp.push(pl);
                }
            }
        }

        setAvailable(availableTemp)
        setSelected(selectedTemp)

    }, [progLangAltMap, currentQuestion])

    return <ModalBackground style={{position: "fixed"}}>

        <FlexBox direction={"column"} direction={"column"} justify={"start"} align={"start"}
                 style={{width: "650px", background: "white", height: "450px"}}>
            <ModalHeader title={"Programming Languages"} setShow={setShow}/>

            <FlexBox direction={"column"} justify={"start"} style={{padding: "15px", width: "100%", height: "100%"}}>

                <FlexBox style={{gap: "20px", width: "100%", height: "100%"}} align={"start"} justify={"space-between"}>
                    <FlexBox direction={"column"} style={{width: "40%"}}>
                            <label style={{textDecoration:"underline", fontWeight:"bold"}}>Available Languages</label><br/>
                            <SingleColTable type={currentQuestion.type} setActive={setActive} active={active} langs={available} />
                    </FlexBox>

                    <FlexBox direction={"column"} style={{height: "100%"}} justify={"space-between"}>
                            <FlexBox direction={"column"} justify={"center"} align={"center"} style={{height:"70%", gap:"10px", width:"20%"}}>
                                <Button onClick={()=>move(setAvailable, setSelected, active)} style={{width:"70px"}} ><ArrowRight/></Button>
                                <Button onClick={()=>move(setSelected, setAvailable, active)} style={{width:"70px"}} variant={"secondary"}><ArrowLeft/></Button>
                        </FlexBox>
                        <Button onClick={saveAlts}>Save</Button>
                    </FlexBox>

                    <FlexBox direction={"column"} style={{width: "40%"}}>
                        <label style={{textDecoration: "underline", fontWeight: "bold"}}>Selected Languages</label><br/>
                            <SingleColTable type={currentQuestion.type} setActive={setActive} active={active} langs={selected} />
                    </FlexBox>
                </FlexBox>
                <br/>
            </FlexBox>


        </FlexBox>
    </ModalBackground>
}

ProgAltModal.propTypes = {};
export const QuestionsExplorer = ({
                               questions, selectedQuestions=[], setSelectedQuestions=()=>{}, setShowQuestionsExplorer, setLoader,
                               activeQuestionIndex = 0, isSelected = false, isViewOnly=false, testName="",testTime="", progLangAltMap, setProgLangAltMap
                                  }) => {

    // Additional state for managing image visibility
    const [showImage, setShowImage] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(activeQuestionIndex)
    const [showTestCasesModal, setShowTestCasesModal] = useState(false)

    // create a copy to prevent outside change
    let currentQuestion = {...questions[currentPosition]};
    const [showProgModal, setShowProgModal] = useState(false)

    const [showAlts, setShowAlts] = useState(false)

    const toggleImageVisibility = () => {
        setShowImage(!showImage);
    };

    useEffect(() => {
        setShowAlts(false)
        if (progLangAltMap && !isViewOnly && currentQuestion.type >= 5 && currentQuestion.alternativesPL) {
            let key = currentQuestion.key;
            if (!key) return;
            for (let i = 0; i < selectedQuestions.length; i++) {
                let q = selectedQuestions[i];
                if (q.key == key) {
                    setShowAlts(true)
                    return;
                }
            }
        }
    }, [currentQuestion, selectedQuestions])

    useEffect(() => {
        setCurrentPosition(activeQuestionIndex);
    }, [activeQuestionIndex])


    const getImageSrc = () => {
        if (currentQuestion && currentQuestion.bucketImageUrl) {
            return currentQuestion.bucketImageUrl;
        } else if (currentQuestion && currentQuestion.fileData) {
            // Assuming fileData is a base64-encoded image string
            return `data:image/png;base64,${currentQuestion.fileData}`;
        }
        return ''; // Default or placeholder image if needed
    };


    const checkHebrew = (question) => {
        if (question.language && question.language == 'Hebrew') {
            // to not convert twice
            if (question.isHebrewChecked) {
                return {fontFamily: "sans-serif"}
            }

            try {
                question.body = b64DecodeUnicode(question.body);
                const bodyText = extractContent(question.body);
                question.title = bodyText.substr(0, 35);

                if (question.answers) {
                    for (let posA = 0; posA < question.answers.length; posA++) {
                        question.answers[posA].body = b64DecodeUnicode(question.answers[posA].body);
                    }
                }
                // to not convert twice
                question.isHebrewChecked = true
            }
            catch (e){}
            return {fontFamily: "sans-serif"}
        }
        return {}
    }

    // check if hebrew fix is needed
    let font = useMemo(() => {
        return checkHebrew(currentQuestion)
    }, [activeQuestionIndex, questions, currentQuestion])

    return (<FlexBox style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        background: "rgba(0,0,0,0.5)",
        zIndex: 11
    }}>
        {showAlts && showProgModal &&
            <ProgAltModal currentQuestion={currentQuestion} setShow={setShowProgModal} setProgLangAltMap={setProgLangAltMap} progLangAltMap={progLangAltMap} />}
        <FlexBox className={"soft-shadow"} direction={"column"} style={{
            width: "1100px", height: "min(800px, 93%)", background: "white", display: "block", position: "relative"
        }}>
            <div style={{
                height: "140px", width: "100%", background: "rgb(71, 96, 241)", color: "white", padding: "30px"
            }}>
                <FlexBox justify={"space-between"} align={"top"}>
                    <h2 style={{fontSize: "37px"}}>{isViewOnly ? testName : "Select Question"}</h2>
                    {isViewOnly ? <div>Test Time: {testTime} Minutes</div> : ""}
                    <div style={{fontSize: "16px", fontWeight: "bold"}}>{currentPosition + 1} Out
                        of {questions && questions.length}</div>
                </FlexBox>

                <br/>
                <FlexBox justify={"space-between"} style={{fontWeight: "300", height: "50px"}} align={"start"}>
                        <span
                            style={{width: "60%"}}><b>Topic: </b> {currentQuestion.areaName} / {currentQuestion.categoryName} / {currentQuestion.versionName} / {currentQuestion.topicName}</span>
                    <span><b>Difficulty: </b> {getDifficultLevel(currentQuestion.difficultLevel)}</span>
                    <Link onClick={e => {
                        e.preventDefault();
                        setLoader(true)
                        let newSelected = [...selectedQuestions]
                        for (const q of questions) {
                            if (!selectedQuestions.find(o => o.key === q.key)) {
                                newSelected.push(q)
                            }
                        }
                        setSelectedQuestions(newSelected)
                        setLoader(false)
                        setShowQuestionsExplorer(false)
                    }} style={{color: "white", textDecoration: "none", padding: 0, margin: 0,display:isViewOnly?"none":""}} to={"#"}>Add
                        All <Plus/></Link>

                </FlexBox>
            </div>
            <div onClick={e => setShowQuestionsExplorer(false)}
                 style={{position: "absolute", top: "10px", right: "10px", cursor: "pointer"}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path
                        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
            </div>
            <FlexBox style={{height: "calc(100% - 210px)", width: "100%", padding: "30px",...font,
                direction: (currentQuestion?.language=='Hebrew' ? "rtl" : "ltr")}}>
                <div style={{
                    width: "50%",
                    maxWidth: "50%",
                    height: "100%",
                    overflowY: "auto",
                    maxHeight: "100%",
                    marginRight: "30px"
                }}>
                    <HighlightText input={currentQuestion.body}/>
                    {/* Conditionally render the Show Image button and image below the question text */}
                    <>
                        {currentQuestion && (
                            <>
                                {currentQuestion.type === 3 && (
                                    <div style={{marginTop: "30px"}}>
                                        <ModalImage
                                            small={getImageSrc()}
                                            large={getImageSrc()}
                                            alt="Question Image"
                                        />
                                    </div>
                                )}

                                {/* Conditionally render the <hr /> tag if it's a type 28 question with downloadable content */}
                                {currentQuestion.type === 28 && (currentQuestion.bucketFileUrl || currentQuestion.secondFileData) && (
                                    <hr style={{marginTop: "30px"}}/>
                                )}

                                {currentQuestion.type === 28 && (currentQuestion.bucketFileUrl || currentQuestion.secondFileData) && (
                                    <div style={{marginTop: "30px", cursor: 'pointer'}} onClick={() => {
                                        if (currentQuestion.bucketFileUrl != null) {
                                            window.open(currentQuestion.bucketFileUrl, '_blank');
                                        } else {
                                            const sampleArr = base64ToArrayBuffer(currentQuestion.secondFileData);
                                            saveByteArray("Sample Report", sampleArr, currentQuestion);
                                        }
                                    }}>
                    	      <img src={showFileIcon} alt="Download Excel" style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                                        <span>OPEN EXCEL</span>
                                    </div>
                                )}
                            </>
                        )}
                    </>

                </div>
                <div style={{width: "50%", height: "100%", overflow: "auto", overflowWrap: "anywhere"}}>
                    <AnswersHolder currentQuestion={currentQuestion} showTestCasesModal={showTestCasesModal} setShowTestCasesModal={setShowTestCasesModal} />
                </div>
            </FlexBox>
            <FlexBox direction={"row"} justify={"space-between"} style={{
                height: "70px", borderTop: "1px solid #bababa", padding: "0px 30px", fontSize: "15px"
            }}>
                <Button onClick={e => setShowQuestionsExplorer(false)} className={"footer-buttons"}
                        variant={"outline-secondary"}>Close</Button>
                <FlexBox direction={"row-reverse"} justify={"space-between"}>
                    <Button className={"footer-buttons"} disabled={currentPosition == questions.length - 1}
                            onClick={e => setCurrentPosition((currentPosition + 1))}><FlexBox>Next&nbsp;
                        <ArrowRightCircle/></FlexBox> </Button>
                    <Button variant={"outline-primary"} className={"footer-buttons"}
                            disabled={currentPosition == 0}
                            onClick={e => currentPosition > 0 ? setCurrentPosition((currentPosition - 1)) : ""}><FlexBox>
                        <ArrowLeftCircle/>&nbsp;Previous</FlexBox>
                    </Button>
                    <Button hidden={isViewOnly}
                            variant={selectedQuestions.find(e => e.key == currentQuestion.key) ? "outline-primary" : "primary"}
                            onClick={e => {
                                if (selectedQuestions.find(e => e.key == currentQuestion.key)) {

                                    if (isSelected) {
                                        // the question to be removed is the last question
                                        if (questions.length == 1) {
                                            setShowQuestionsExplorer(false)
                                            setSelectedQuestions([])
                                        }
                                        // there are at least 2 questions
                                        else {
                                            // changing to the other question
                                            if (currentPosition == 0) {
                                                setSelectedQuestions(selectedQuestions.filter(e => e.key != currentQuestion.key));
                                                currentQuestion = questions[1];
                                            } else {
                                                setCurrentPosition(currentPosition - 1)
                                                setSelectedQuestions(selectedQuestions.filter(e => e.key != currentQuestion.key));
                                                currentQuestion = questions[currentPosition - 1];
                                            }
                                            // removing the question

                                        }
                                    } else {
                                        setSelectedQuestions(selectedQuestions.filter(e => e.key != currentQuestion.key));
                                    }
                                } else {
                                    setSelectedQuestions([...selectedQuestions, currentQuestion])
                                }
                            }}>{selectedQuestions.find(e => e.key == currentQuestion.key) ?
                        <FlexBox> <ClipboardMinus/>&nbsp;&nbsp;Remove Question&nbsp;</FlexBox> :
                        <FlexBox> <ClipboardPlus/>&nbsp;&nbsp;Select Question</FlexBox>} </Button>

                    {showAlts && <Button onClick={()=> setShowProgModal(true)} style={{marginRight:"10px"}} hidden={isViewOnly} variant={"primary"}>
                            {<FlexBox> <PlusCircle/>&nbsp;&nbsp;Add Programming Languages&nbsp;</FlexBox>}
                        </Button>}

                    {showTestCasesModal && <TestCasesModal type={currentQuestion.type} testCases={currentQuestion.answers.slice(2)} setShowTestCases={setShowTestCasesModal}/>}


                </FlexBox>
            </FlexBox>
        </FlexBox>
    </FlexBox>)
}

const getPLMapString = (progLangAltMap) => {
    let data = "";
    for (let key in progLangAltMap) {
        if (data.length > 0) {
            data = data + "__NEWLINE__";
        }
        data = data + key + "__VAL__" + progLangAltMap[key];
    }
    return data;
}

const AnswersHolder = ({currentQuestion, setShowTestCasesModal}) => {
    // code question
    if (currentQuestion.type == 28) {
        return "Answer - Excel File Upload";
    }
    else if (currentQuestion.type >= 5) {
        return <CodingAnswer currentQuestion={currentQuestion} setShowTestCasesModal={setShowTestCasesModal} />
    }
    else if(currentQuestion.isRecordedQuestion){
        return "Video Recorded Question";
    }
    else if (!currentQuestion.isOpen) {
        return <MultipleChoice currentQuestion={currentQuestion}/>;
    }
    else{
        let ow = currentQuestion.oeAnswerBody ? extractContent(currentQuestion.oeAnswerBody) : "";
        if (currentQuestion.allowUploadAnswer) {
            return "Answer - Upload File";
        }
        else if(currentQuestion &&  currentQuestion.allowUploadMultipleFiles){
            return "Answer - Upload Files";
        }
        else if(currentQuestion.isAITextImageOpenQuestion){
            return "Answer - Open Text and Image Upload - AI";
        }
        else if(currentQuestion.isScoredOpenQuestion){
            return <>Answer - Open Text - Keywords <br/> {ow} <br/> <MultipleChoice currentQuestion={currentQuestion} /></>;
        }
        else if(currentQuestion.isAIScoredOpenQuestion){
            if (currentQuestion.additionalInstructionsForAiQuestions && currentQuestion.additionalInstructionsForAiQuestions.trim()) {
                return <>Answer - Open Text - AI <br/> {ow} <br/>Answer criteria: {currentQuestion.additionalInstructionsForAiQuestions}</>;
            }
            else{
                return <>Answer - Open Text - AI <br/> {ow}</>;
            }
        }
        else if(currentQuestion.isWhiteboardQuestion){
            return "Whiteboard Question";
        }
        else{
            if (currentQuestion.isAdvancedEditor) {
	 			if(currentQuestion.isAIScoredOpenQuestion){
					return <>Answer - Open Coding Text - AI Scored <br/> {ow}</>;
				}
				else {
					return <>Answer - Open Coding Text <br/> {ow}</>;	
				}
                
            }
            else{
                return <>Answer - Open Text <br/> {ow}</>;
            }

        }
    }


    return ""
}

const CodingAnswer = ({currentQuestion, setShowTestCasesModal}) => {
    if (currentQuestion.answers && currentQuestion.answers.length > 0 && currentQuestion.type != 9 && currentQuestion.type >= 29 && currentQuestion.type <= 32 ) {	
        return <>
            <Editor value={currentQuestion.answers[0]?.body} mode={currentQuestion.type} showTestCases={true}/>
            {(currentQuestion.answers.length >= 1) ? <Button onClick={e=>setShowTestCasesModal(true)} variant={"outline-primary"} style={{marginTop:"7px", fontSize:"15px"}}><ListCheck/> Test Cases</Button> : "" }
        </>
    }
    else{
        return <Editor value={""} mode={currentQuestion.type}/>
    }
}

const TestCase = ({tc, type}) => {
    return <Editor value={tc.body} mode={type} height={"350px"}/>
}

const TestCasesModal = ({testCases, setShowTestCases, type}) => {
    return <ModalBackground style={{position: "fixed"}}>
        <div style={{width: "750px", background: "white", height:"70%"}}>
            <ModalHeader title={"Test Cases"} setShow={setShowTestCases}/>
            <div style={{width: "100%", height: "90%", overflowY:"auto", padding:"15px"}}>
                {testCases.map((tc, index) => <div>
                            <label>Test Case {index + 1}</label>
                            <TestCase type={type} tc={tc}/>
                            <br/>
                    </div>
                )}
            </div>
        </div>
    </ModalBackground>
}

const MultipleChoice = ({currentQuestion}) => {
    if (currentQuestion.answers && currentQuestion.answers.length > 0) {
        return <div style={{padding: "20px"}}>
            {currentQuestion.answers.map(a => <Answer isHebrew={currentQuestion?.language == 'Hebrew'} answer={a}/>)}
        </div>
    }
    return (<div style={{padding: "20px"}}>
        <Answer answer={{correct: false, body: "Example Answer"}}/>
        <Answer answer={{correct: false, body: "Example Answer"}}/>
        <Answer answer={{correct: true, body: "Example Correct Answer"}}/>
        <Answer answer={{correct: false, body: "Example Answer"}}/>
    </div>)
}


const CorrectAnswerCaret = ({rtl}) => {
    let directionMargin = (rtl ? {marginLeft: "15px"} : {marginRight: "20px"});
    return <CheckCircleFill fill={"#86d038"} size={18} style={{marginTop: "5px", minWidth: 18, ...directionMargin}}/>
}

const IncorrectAnswerCaret = ({rtl}) => {
    let directionMargin = (rtl ? {marginLeft: "15px"} : {marginRight: "20px"});
    return <CaretRightFill fill={"#e5e4e4"} size={15} style={{marginTop: "5px", minWidth: 18, ...directionMargin}}/>

}

const Answer = ({answer, isHebrew = false}) => {
    return (
        <FlexBox key={"answer" + 4} justify={"start"} align={"start"} style={{marginBottom: "20px", width: "100%"}}>
            {answer?.correct ? <CorrectAnswerCaret rtl={isHebrew}/> : <IncorrectAnswerCaret rtl={isHebrew}/>}
            <div>{<HighlightText input={answer.body}/>}</div>
        </FlexBox>
    )
}


const PreferencesSettings = ({test, setTest, setShow, defaultSubject, setDefaultSubject, subject, setSubject, defaultContent, setDefaultContent, content, setContent}) => {


    return (<FlexBox align={"start"} style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: "1000",
        background: "rgba(0,0,0,.25)"
    }}>

        <div className={"hard-shadow"} style={{
            marginTop: "5%", zIndex: "1001", width: "600px", height: "200px", background: "white", position: "relative"
        }}>
            <div style={{
                textAlign: "left", width: "100%", height: "80px", background: "#4760F1", position: "relative"
            }}>
                <FlexBox justify={"start"} align={"center"} style={{
                    lineHeight: "20px",
                    color: "#fff",
                    fontWeight: "800",
                    fontSize: "20px",
                    height: "100%",
                    padding: "20px"
                }}><span>Mail Preferences</span>
                    <X size={"25px"} onClick={(e) => setShow(false)}
                       style={{position: "absolute", right: 15, top: 15, cursor: "pointer"}}></X>
                </FlexBox>


            </div>
            <div>
                <PreferencesBody defaultContent={defaultContent} setContent={setContent} content={content}
                                 defaultSubject={defaultSubject} setDefaultContent={setDefaultContent}
                                 setDefaultSubject={setDefaultSubject} setSubject={setSubject} subject={subject}
                                 company={null}/>

            </div>

        </div>
    </FlexBox>)
}

const TestPreferencesSettings = ({setShow, test, setTest, selectedQuestions}) => {

    const setQuestionMultiplier = (key, value) => {
        let newWeights = {}
        if (test && test.questionsWeights) {
            newWeights = {...test.questionsWeights};
        }
        newWeights[key] = value;
        setTest({...test, questionsWeights: newWeights})
    }

    return (<FlexBox align={"start"} style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: "1000",
        background: "rgba(0,0,0,.25)"
    }}>

        <div className={"hard-shadow"} style={{
            marginTop: "5%", zIndex: "1001", width: "800px", maxHeight:"800px", background: "white", position: "relative"
        }}>
            <div style={{
                textAlign: "left", width: "100%", height: "80px", background: "#4760F1", position: "relative"
            }}>
                <FlexBox justify={"start"} align={"center"} style={{
                    lineHeight: "20px",
                    color: "#fff",
                    fontWeight: "800",
                    fontSize: "20px",
                    height: "100%",
                    padding: "20px"
                }}><span>Test Preferences</span>
                    <X size={"25px"} onClick={(e) => setShow(false)}
                       style={{position: "absolute", right: 15, top: 15, cursor: "pointer"}}></X>
                </FlexBox>


            </div>
            <div style={{padding: "15px", maxHeight: "500px", overflow: "auto"}}>
                <Table>
                    <TableHead columns={["", "Title", "Difficulty", "Score Multiplier"]}>
                    </TableHead>
                    <TableBody>
                        {
                            selectedQuestions && selectedQuestions.map((q, i) => {
                                let multiplier = 1
                                if (test.questionsWeights && test.questionsWeights[q.key]) {
                                    multiplier = test.questionsWeights[q.key]
                                }
                                return <TableRow>
                                    <td style={{textAlign: "center", color: "#D1D2D3"}}>{+i + 1}</td>
                                    <td>{q.title}</td>
                                    <td>{getDifficultLevel(q.difficultLevel)}</td>
                                    <td style={{width: "120px"}}><InputText type={"number"} field={multiplier}
                                                                            setField={e => setQuestionMultiplier(q.key, Math.min(20, Math.abs(Math.round(e.target.value || 1))))}/>
                                    </td>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>

            </div>

        </div>
    </FlexBox>)
}

const SupervisedSettings = ({test, setTest, setShow}) => {
    return (<FlexBox align={"start"} style={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: "1000",
        background: "rgba(0,0,0,.25)"
    }}>

        <div className={"hard-shadow"} style={{
            marginTop: "10%", zIndex: "1001", width: "600px", height: "200px", background: "white", position: "relative"
        }}>
            <div style={{
                textAlign: "left", width: "100%", height: "80px", background: "#4760F1", position: "relative"
            }}>
                <FlexBox justify={"start"} align={"center"} style={{
                    lineHeight: "20px",
                    color: "#fff",
                    fontWeight: "800",
                    fontSize: "20px",
                    height: "100%",
                    padding: "20px"
                }}><span>Supervised Tests Options</span>
                    <X size={"25px"} onClick={(e) => setShow(false)}
                       style={{position: "absolute", right: 15, top: 15, cursor: "pointer"}}></X>
                </FlexBox>


            </div>
            <div style={{padding: "20px"}}>
                <FlexBox justify={"start"}>
                    <Checkbox value={test.useForceFocus} label={"Stay on Test Tab"} setValue={(e) => {
                        if (e.target.checked) {
                            setTest({...test, useForceFocus: true, usePrintScreen: false})
                        } else {
                            setTest({...test, useForceFocus: false})
                        }
                    }}/>
                    <Tooltip style={{marginLeft: "15px"}}
                             text={"During the test, the applicant will not be allowed to use Google and be asked to stay on the test tab. can not be used with Enable Screenshot."}/>
                </FlexBox>

                <FlexBox justify={"start"}>
                    <Checkbox value={test.usePrintScreen} label={"Enable Screenshots"} setValue={(e) => {
                        if (e.target.checked) {
                            setTest({...test, usePrintScreen: e.target.checked, useForceFocus: false})
                        } else {
                            setTest({...test, usePrintScreen: e.target.checked})
                        }

                    }}/>
                    <Tooltip style={{marginLeft: "15px"}}
                             text={"During the test, the testing application will take screenshots of the applicant’s screen every minute when the test tab isn't active. can not be used with Stay on Test Tab."}/>
                </FlexBox>

                <FlexBox justify={"start"}>
                    <Checkbox value={test.useWebCamera} label={"Use WebCam"}
                              setValue={(e) => setTest({...test, useWebCamera: e.target.checked})}/>

                </FlexBox>


            </div>

        </div>
    </FlexBox>)
}

function Browse({selectedQuestions, setSelectedQuestions, companyKey, companyName, setLoaderArea, progLangAltMap, setProgLangAltMap}) {
    const [areas, setAreas] = useState([])
    const [area, setArea] = useState(NO_OPTION)
    const [category, setCategory] = useState(NO_OPTION)
    const [version, setVersion] = useState(NO_OPTION)
    const [topic, setTopic] = useState(NO_OPTION)
    const [difficulty, setDifficulty] = useState(NO_OPTION)
    const [showQuestionsExplorer, setShowQuestionsExplorer] = useState(false)
    const [questions, setQuestions] = useState([])
    const [pqDifficulties, setPQDifficulties] = useState([0, 0, 0])

    useEffect(() => {

        if (area == "Private Questions") {
            let areaPQ = areas.find(e => e.name == "Private Questions");
            if (!areaPQ) return;
            if (!areaPQ.categories) {
                setLoaderArea(true)
                axios.post("/GetCompanyPrivateCategories.html?companyKey=" + encodeURIComponent(companyKey))
                    .then(res => {
                        setLoaderArea(false)
                        areaPQ = {...areaPQ, categories: res.data}
                        let newAreas = areas.filter(a => a.name != "Private Questions");
                        newAreas.push(areaPQ)
                        setAreas(newAreas);

                    })
                    .catch((err) => {
                        setLoaderArea(false)
                        console.log(err);
                    })
            }
        }
    }, [area])

    useEffect(() => {
        if (!companyKey || !companyName || areas.length > 0) return;
        setLoaderArea(true)
        axios({
            method: 'get', url: "/GetAreasDetails.html?companyId=" + companyKey + "&companyName=" + companyName +"&isLiteCompanyRequest=true"
        }).then((response) => {
            let tempAreas = response.data;
            tempAreas.push({name: "Private Questions"});
            setAreas(tempAreas);
            setLoaderArea(false)
        }).catch((err) => {
            console.log(err);

        });
    }, [companyKey, companyName])


    const getQuestions = () => {
        if (difficulty == "dis" || !difficulty) return;
        setLoaderArea(true)
        if (area == "Private Questions") {
            axios.get(API_URL_PREFIX + "/GetPrivateCodingQuestionsByTopic.html?topicKey=" + topic.split("%")[0] + "&difLevel=" + difficulty)
                .then(res => {
                    setLoaderArea(false)
                    if (!res.data || res.data.length == 0) {
                        CustomInfoToast("No questions were found");
                    } else {
                        setQuestions(res.data)
                        setShowQuestionsExplorer(true)
                    }
                })
                .catch(e => {
                    console.log(e)
                    setLoaderArea(false)
                })
        }
        else{
            axios.get(API_URL_PREFIX + "/GetCodingQuestionsByTopic.html?topicKey=" + topic.split("%")[0] + "&difLevel=" + difficulty)
                .then(res => {
                    setLoaderArea(false)
                    if (!res.data || res.data.length == 0) {
                        CustomInfoToast("No questions were found");
                    } else {
                        setQuestions(res.data)
                        setShowQuestionsExplorer(true)
                    }
                })
                .catch(e => {
                    console.log(e)
                    setLoaderArea(false)
                })
        }

    }
    useEffect(getQuestions, [difficulty])

    useEffect(() => {
        if (topic && area == "Private Questions") {
            getPrivateQuestionsDifficulties();
        }
    }, [topic]);

    const changeSelect = (field, value) => {
        if (field == "area" && value == "Private Questions") {
            setDifficulty(NO_OPTION)
            setTopic(NO_OPTION)
            setVersion("Custom")
            setCategory("Custom")
            setArea(value)
            return;
        }
        switch (field) {
            case "area":
                setDifficulty(NO_OPTION)
                setTopic(NO_OPTION)
                setVersion(NO_OPTION)
                setCategory(NO_OPTION)
                setArea(value)
                break;
            case "category":
                setDifficulty(NO_OPTION)
                setTopic(NO_OPTION)
                setVersion(NO_OPTION)
                setCategory(value)
                break;
            case "version":
                setDifficulty(NO_OPTION)
                setTopic(NO_OPTION)
                setVersion(value)
                break;
            case "topic":
                setDifficulty(NO_OPTION)
                setTopic(value)
                break;
            case "difficulty":
                setDifficulty(value)
                break;
        }
    }

    const getPrivateQuestionsDifficulties = () => {
        setLoaderArea(true)
        axios.get(API_URL_PREFIX + "/GetPrivateCodingQuestionsTopicDiffs.html?topicKey=" + topic.split("%")[0])
            .then(res => {
                setLoaderArea(false)
                // show all if we don't know what is the difficulties.
                setPQDifficulties(res.data || [1, 1, 1])
            })
            .catch(e => {
                setLoaderArea(false)
                // show all if we don't know what is the difficulties.
                setPQDifficulties([1, 1, 1]);
                console.log(e);
            })
    }

    return <FlexBox justify={"space-between"} style={{position: "relative"}}>
        {showQuestionsExplorer ?
            <QuestionsExplorer setLoader={setLoaderArea} setShowQuestionsExplorer={setShowQuestionsExplorer}
                               questions={questions} setQuestions={setQuestions}
                               setSelectedQuestions={setSelectedQuestions} setProgLangAltMap={setProgLangAltMap} progLangAltMap={progLangAltMap}
                               selectedQuestions={selectedQuestions}/> : ""}

        <FlexBox style={{width: "95%"}} justify={"start"}>

            <select disabled={!areas.length} value={area} onChange={e => changeSelect("area", e.target.value)}
                    className={"areasSelect"}>
                <option value={NO_OPTION} disabled='disabled'>Select Area</option>
                {areas.map(a => <option key={"a" + a.name} value={a.name}>{a.name}</option>)}
            </select>
            <select hidden={area == "Private Questions"} disabled={area == NO_OPTION} value={category}
                    onChange={e => changeSelect("category", e.target.value)} className={"areasSelect"}>
                <option value={NO_OPTION} disabled='disabled'>Select Category</option>
                {area != NO_OPTION && (() => {
                    let categories = areas.find(e => e.name == area)?.categories;
                    if (!categories) return "";
                    return categories.map(c => <option key={"c" + c.name} value={c.name}>{c.name}</option>)
                })()};
            </select>
            <select hidden={area == "Private Questions"} disabled={category == NO_OPTION} value={version}
                    onChange={e => changeSelect("version", e.target.value)} className={"areasSelect"}>
                <option value={NO_OPTION} disabled='disabled'>Select Version</option>
                {category != NO_OPTION && (() => {
                    let categories = areas.find(e => e.name == area)?.categories;
                    if (!categories) return "";
                    let versions = categories.find(c => c.name == category)?.versions
                    if (!versions) return "";
                    return versions.map(v => <option key={"v" + v.name} value={v.name}>{v.name}</option>)
                })()};
            </select>
            <select disabled={version == NO_OPTION} value={topic} onChange={e => changeSelect("topic", e.target.value)}
                    className={"areasSelect"}>
                <option value={NO_OPTION} disabled='disabled'>Select Topic</option>
                {version != NO_OPTION && (() => {
                    let categories = areas.find(e => e.name == area)?.categories;
                    if (!categories) return "";
                    let versions = categories.find(c => c.name == category)?.versions
                    if (!versions) return "";
                    let topics = versions.find(v => v.name == version)?.topics
                    if (!topics) return "";
                    return topics.map(t => <option key={"t" + t.name} value={t.key + "%" + t.name}>{t.name}</option>)
                })()};
            </select>
            <select disabled={topic == NO_OPTION} value={difficulty}
                    onChange={e => changeSelect("difficulty", e.target.value)} className={"areasSelect"}>
                <option value={NO_OPTION} disabled='disabled'>Select Difficulty</option>

                {topic != NO_OPTION && (() => {
                        let categories = areas.find(e => e.name == area)?.categories;
                        if (!categories) return "";
                        let versions = categories.find(c => c.name == category)?.versions
                        if (!versions) return "";
                        let topics = versions.find(v => v.name == version)?.topics
                        if (!topics) return "";
                        let selectedTopic = topics.find(e => e.key == topic.split("%")[0])
                        if (!selectedTopic) return "";
                        let difficulties = []

                        if (area == "Private Questions") {
                            try {
                                let diffs = pqDifficulties;
                                if (diffs.length > 0 && diffs[0] > 0) {
                                    difficulties.push(<option key={"d2"} value={2}>Junior</option>)
                                }
                                if (diffs.length > 1 && diffs[1] > 0) {
                                    difficulties.push(<option key={"d3"} value={3}>Senior</option>)
                                }
                                if (diffs.length > 2 && diffs[2] > 0) {
                                    difficulties.push(<option key={"d4"} value={4}>Expert</option>)
                                }
                            } catch (e) {
                                console.log(e)
                            }
                        } else {
                            if (selectedTopic.numberOfAdvancedBeginnerQuestions > 0) {
                                difficulties.push(<option key={"d2"} value={2}>Junior</option>)
                            }
                            if (selectedTopic.numberOfSeniorQuestions > 0) {
                                difficulties.push(<option key={"d3"} value={3}>Senior</option>)
                            }
                            if (selectedTopic.numberOfExpertQuestions > 0) {
                                difficulties.push(<option key={"d4"} value={4}>Expert</option>)
                            }
                        }
                        return difficulties
                    }
                )()};
            </select>
        </FlexBox>
        <Button onClick={getQuestions} disabled={difficulty == NO_OPTION} className={"browse"}>Browse</Button>
    </FlexBox>;
}

function SelectedQuestionsTable({
                                    selectedQuestions,
                                    progLangAltMap,
                                    setActiveQuestionIndex,
                                    setShowQuestionsExplorer,
                                    setSelectedQuestions
                                }) {

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(selectedQuestions);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setSelectedQuestions(items);
    };


    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Table style={{tableLayout: "auto", width: "100%"}}>
                            <TableHead columns={["", "Title", "Category", "Version", "Topic", "Difficulty", ""]}/>
                            <TableBody>
                                {selectedQuestions.map((q, i) => {
                                    let newValue = q.categoryName;
                                    if (progLangAltMap && q.alternativesPL) {
                                        // compute the new value
                                        let alts = [];
                                        for (const k of Object.keys(progLangAltMap)) {
                                            if (k.includes(q.key)) {
                                                alts.push(k.split("__KEY__")[1].replace("CSharp", "C#").replace("Cpp", "C++").replace("GoLang", "Go"));
                                            }
                                        }
                                        if (alts.length > 0) {
                                            newValue += ", " + alts.join(", ");
                                        }
                                    }

                                    return (
                                        <Draggable key={q.key} draggableId={q.key} index={i}>
                                            {(provided, snapshot) => (
                                                <tr className={`table-row ${snapshot.isDragging ? 'dragging-row' : ''}`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{...provided.draggableProps.style, height: "40px"}}
                                                >
                                                    <td style={{textAlign: "center", color: "#D1D2D3"}}>{+i + 1}</td>
                                                    <td>
                                                        <Link
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                setActiveQuestionIndex(i);
                                                                setShowQuestionsExplorer(true);
                                                            }}
                                                            style={{fontSize: "15px", fontWeight: "600"}}
                                                            className={"candidate-link"}
                                                            to={"#"}
                                                        >
                                                            &nbsp;{extractContent(q.body).substring(0, 32)}
                                                        </Link>
                                                    </td>
                                                    <td>{newValue}</td>
                                                    <td>{q.versionName}</td>
                                                    <td>{q.topicName}</td>
                                                    <td>{getDifficultLevel(q.difficultLevel)}</td>
                                                    <td>
                                                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"start", width:"100%"}}>
                                                            <span onClick={() => setSelectedQuestions(selectedQuestions.filter(e => e.key !== q.key))} className={"deleteQuestion"}>
                                                              <img src={deleteQuestion} alt={"Delete Question"}/>
                                                            </span>
                                                            <span {...provided.dragHandleProps}><GripVertical  color={"gray"} size={"22px"} /></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </Droppable>
        </DragDropContext>);
}

function Body({test, setTest, companyKey, companyName, setLoaderArea, progLangAltMap, setProgLangAltMap, selectedQuestions, setSelectedQuestions}) {
    const [showQuestionsExplorer, setShowQuestionsExplorer] = useState(false)
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0)


    useEffect(() => {
        setTest({...test, amountOfQuestions: selectedQuestions.length, questionsIds: selectedQuestions.map(q => q.key)})
    }, [selectedQuestions])

    useEffect(() => {
        // if the test questions wasn't fetched yet
        if (test.key && test.amountOfQuestions > 0 && test.questionsIds.length > 0 && selectedQuestions.length == 0) {
            setSelectedQuestions(test.quizQuestions)
        }
    }, [test])


    return (<div className={"container soft-shadow"}
                 style={{
                     height: "530px", maxWidth: MAX_WIDTH, padding: "20px", background: "#fff",
                 }}>
        {showQuestionsExplorer ?
            <QuestionsExplorer setLoader={false} setShowQuestionsExplorer={setShowQuestionsExplorer}
                               questions={selectedQuestions}
                               setSelectedQuestions={setSelectedQuestions}
                               selectedQuestions={selectedQuestions} activeQuestionIndex={activeQuestionIndex}
                               isSelected={true} setProgLangAltMap={setProgLangAltMap} progLangAltMap={progLangAltMap}
            /> : ""}
        <FlexBox style={{marginBottom: "10px"}} justify={"space-between"}>
            <div style={{
                lineHeight: "20px", color: "#979DA5", fontWeight: "800", fontSize: "20px"
            }}>Questions
            </div>
            <div>


            </div>
        </FlexBox>

        <Browse setProgLangAltMap={setProgLangAltMap} progLangAltMap={progLangAltMap} companyKey={companyKey} companyName={companyName} selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions} setLoaderArea={setLoaderArea}/>

        <br/>
        <div style={{height: "400px", display: "block", padding: "0", overflow: "auto", margin: "0"}}>
            <SelectedQuestionsTable selectedQuestions={selectedQuestions} progLangAltMap={progLangAltMap}
                                    setActiveQuestionIndex={setActiveQuestionIndex}
                                    setShowQuestionsExplorer={setShowQuestionsExplorer}
                                    setSelectedQuestions={setSelectedQuestions}/>
        </div>
    </div>);
}

const Create = ({test, defaultSubject, subject, defaultContent, content, progLangAltMap, notifyList}) => {
    const navigate = useNavigate()
    const createTest = () => {

        if (!test.amountOfQuestions || !test.questionsIds) {
            CustomErrorToast("Please select at least 1 question fo the test.")
            return;
        }
        if (!test.name) {
            CustomErrorToast("Please fill the Test Name")
            return;
        }

        if (!test.timePerQuiz && !test.timePerQuiz == 0) {
            CustomErrorToast("Please fill the Test Time")
            return;
        }

        if (!test.expirationPeriod && !test.expirationPeriod == 0) {
            CustomErrorToast("Please fill the Test Time")
            return;
        }
        let subTest = {...test, questionsIds: (test.questionsIds.join(DELIMETER_BETWEEN_QUESTIONS))}

        // generate the test categories intro from the questions

        subTest['questionsDescription'] = generateDescriptionFromQuestions(subTest.selectedQuestions)

        let data = getPLMapString(progLangAltMap);
        if (data && data.length > 0) {
            subTest.progLangAlternatives = data;
        }

        subTest.notifyList = notifyList.join(",");

        // filter out from questionsWeights the questions that are not in the test
        if (test.questionsWeights) {
            let newWeights = {}
            for (const key in test.questionsWeights) {
                if (test.questionsIds.includes(key)) {
                    newWeights[key] = test.questionsWeights[key]
                }
            }
            subTest.questionsWeights = newWeights;
        }
        else {
            subTest.questionsWeights = {}
        }


        delete subTest.selectedQuestions;
        delete subTest.questionsIdsString;
        debugger
        if (!defaultContent) {
            // make sure all the tags are in the template
            if (!content.includes("{CandidateName}") || !content.includes("{TestName}") || !content.includes("{TestTopics}") || !content.includes("{TimeLimit}") || !content.includes("{TestLink}") || !content.includes("{CompanyName}")) {
                CustomErrorToast('Please make sure all the tags are in the invite')
                return;
            }
            subTest["customInviteContent"] = content;
        }
        else{
            // empty means we will take the default
            subTest["customInviteContent"] = ''
        }

        if (!defaultSubject) {
            subTest["customInviteSubject"] = subject
        }
        else{
            subTest["customInviteSubject"] = ''
        }


        let id = CustomLoadingToast("Processing")
        axios.post(API_URL_PREFIX + "/SaveLiteTest.html", subTest)
            .then(response => {
                const data = response.data;
                if (data && !data.includes('err:\'true\'')) {
                    updateCustomSuccessToast("Success!", id)
                    navigate(APP_URL_PREFIX + "/test/list")
                } else {
                    updateCustomErrorToast("Error processing test.", id)
                }
            })
            .catch(e => {
                updateCustomErrorToast("Error processing test.", id)
            })
    }

    return (<div className={"container"}
                 style={{
                     maxWidth: MAX_WIDTH, padding: "10px 0px", textAlign: "right"
                 }}>
        <CustomToastContainer/>
        <Button onClick={createTest}
                style={{width: "120px", borderRadius: "0px"}}>{test.key ? "Save " : "Create "} Test</Button>
    </div>);
}
