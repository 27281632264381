import TransparentPageLayout from "../../../layout/page-layout/TransparentPageLayout";
import FlexBox from "../../../components/utilities/FlexBox";
import {useEffect, useState, useContext} from "react";
import {Button} from "react-bootstrap";
import InputText from "../../../components/utilities/InputText";
import checkEmail from "../../user/login-register/Utilities";
import axios from "axios";
import {checkMobile} from "../../../utilities/Utilities";
import {API_URL_PREFIX, APP_URL_PREFIX} from "../../../utilities/Constants";
import {useNavigate, useParams} from "react-router-dom";
import {CustomErrorToast, CustomToastContainer} from "../../../components/utilities/CustomToast";

import CompanyTeamContext from "../../../CompanyTeamContext";


const NewCandidate = () => {
    const [name, setName] = useState({firstName: "", middleName: "", lastName: ""})
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [notes, setNotes] = useState("")
    const [submit, setSubmit] = useState(false)
    const {id} = useParams();
    const navigate = useNavigate()
    const company = useContext(CompanyTeamContext)
    
    useEffect(() => {
        if (id) {
            axios.get(API_URL_PREFIX+"/GetCandidate.html?id=" + id)
                .then(res => res.data)
                .then(data => {
                    if (data) {
                        const {firstName, middleName, lastName, email, mobile, notes} = data
                        setName({firstName, middleName, lastName});
                        setEmail(email)
                        setMobile(mobile)
                        setNotes(notes)
                        if(company){
                            document.title  = firstName+" - " + company.basicTitle
                        }

                    }
                })
                .catch()
        }
        else{
            if(company){
                document.title  = "New Candidate - " + company.basicTitle
            }
        }
        return () => {
            if(company){
                document.title = company.basicTitle
            }
        }

    }, [id, company])

    const saveCandidate = () => {
        if (!name.firstName || !name.lastName) {
            CustomErrorToast("Fill the candidate name.")
            return;
        }
        if(mobile && !checkMobile(mobile)){
            CustomErrorToast("Invalid mobile number.")
            return;
        }
        if (!email) {
            CustomErrorToast("Fill the candidate email.")
            return;
        } else {
            if (!checkEmail(email)) {
                CustomErrorToast("Invalid candidate email.")
                return;
            }
        }
        setSubmit(true)
        const successHandler = (res) => {
            setSubmit(false)
            if (res.data.result) {
                navigate( APP_URL_PREFIX+"/candidate/" + res.data.info);

            } else {
                CustomErrorToast(res.data.info || "Error saving candidate, Please try again.")
            }
        }
        const errorHandler = (error) => {
            setSubmit(false)
            CustomErrorToast(error)
        }


        if (!id) {
            axios.post(API_URL_PREFIX+"/AddCandidate.html", {...name, email, mobile, notes})
                .then(successHandler)
                .catch(errorHandler)
        } else {
            axios.post(API_URL_PREFIX+"/AddCandidate.html", {key:id,...name, email, mobile, notes})
                .then(successHandler)
                .catch(errorHandler)
        }

    }

    return (
        <TransparentPageLayout>
            <CustomToastContainer/>
            <FlexBox direction={"column"} justify={"center"} align={"start"}
                     style={{background: "#fff", height: "80%", maxWidth: "800px"}} className={"hard-shadow"}>
                <div style={{
                    color: "#364454",
                    fontWeight: "700",
                    fontSize: "20px",
                    borderBottom: "1px solid #ebebeb",
                    width: "100%",
                    padding: "15px 30px"
                }}>{id ? "Update" : "Add"} Candidate
                </div>

                <br/>
                <FlexBox justify={"start"} align={"end"} style={{width: "100%", padding: "10px 30px"}}>
                    <InputText idName={"firstName"} required={true} label={"First name"} field={name.firstName}
                               setField={e => setName({...name, firstName: e.target.value})}/>
                    <InputText label={"Middle Name"} field={name.middleName}
                               setField={e => setName({...name, middleName: e.target.value})}/>
                    <InputText idName={"lastName"} required={true} label={"Last name"} field={name.lastName}
                               setField={e => setName({...name, lastName: e.target.value})}/>
                </FlexBox>

                <FlexBox justify={"start"} align={"end"}
                         style={{width: "100%", padding: "10px 30px", borderBottom: "1px solid #ebebeb"}}>
                    <InputText idName={"email"} required={true} label={"Email"} field={email} setField={e => setEmail(e.target.value)}/>
                    <InputText type={"tel"} label={"Mobile Phone"} field={mobile}
                               setField={e => setMobile(e.target.value)}/>
                    <br/>
                    <br/>
                </FlexBox>
                <FlexBox justify={"start"} direction={"column"} align={"start"}
                         style={{width: "100%", padding: "10px 30px"}}>
                    <label style={{fontSize: "14px"}} className="form-label">{"Notes"}</label>
                    <textarea value={notes} onChange={e => setNotes(e.target.value)} className={"input"} rows="4" col
                              style={{fontSize: "14px", width: "100%",}}/>
                </FlexBox>
                <FlexBox justify={"space-between"} align={"end"}
                         style={{width: "100%", padding: "10px 30px", borderTop: "1px solid #ebebeb"}}>


                    <Button onClick={()=> navigate(-1)}
                            style={{float: "right", width: "80px", borderRadius: "2px", fontSize: "14px"}}
                            variant={"outline-secondary"}>Back</Button>

                    <Button id={"saveCandidate"} disabled={submit} onClick={saveCandidate}
                            style={{float: "right", width: "80px", borderRadius: "2px", fontSize: "14px"}}
                            variant={"primary"}>{id ? "Update" : "Add"}</Button>



                </FlexBox>
            </FlexBox>
        </TransparentPageLayout>
    )

}
export default NewCandidate