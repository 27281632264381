import ProfileImage from "../../../components/profile/ProfileImage";
import {useContext, useEffect, useState} from "react";
import FlexBox from "../../../components/utilities/FlexBox";
import TableHead from "../../../components/utilities/table/TableHead";
import PageLayout from "../../../layout/page-layout/PageLayout";
import Table from "../../../components/utilities/table/Table";
import './CandidatesList.css'
import axios from "axios";
import {
    getCandidateFullName, getUrlParameter
} from "../../../utilities/Utilities";
import {
    API_URL_PREFIX,
    APP_URL_PREFIX,
} from "../../../utilities/Constants";
import Loader from "../../../components/utilities/Loader";
import {
    CustomErrorToast,
    CustomLoadingToast,
    updateCustomErrorToast,
    updateCustomSuccessToast
} from "../../../components/utilities/CustomToast";
import {Button} from "react-bootstrap";
import SendTest, {SendTestsCSV} from "../../../components/send-test/SendTest";
import {Pencil, X} from "react-bootstrap-icons";
import ConfirmModal from "../../../components/utilities/ConfirmModal";
import SearchBar from "../../../components/search/SearchBar";
import {Link, useNavigate} from "react-router-dom";
import CompanyTeamContext from "../../../CompanyTeamContext";


const CandidatesList = () => {
    const [keyword, setKeyword] = useState(getUrlParameter("kw") || "")
    const [candidates, setCandidates] = useState([]);
    const [loader, setLoader] = useState(true)
    const [showCSV, setShowCSV] = useState(false)
    const company = useContext(CompanyTeamContext)
    let sentTestsQuota = 0;
    if(company){
        sentTestsQuota = company.sentTestsQuota
    }


    const getAllCandidates = () =>{
        axios.get(  API_URL_PREFIX+"/GetAllCandidates.html")
            .then(response => {
                setLoader(false)
                let cands = response.data || []
                cands = cands.sort((a,b)=>{
                    return b.lastModified - a.lastModified
                })
                setCandidates(cands)
            })
            .catch(e => {
                setLoader(false)
                if(e.response.status == 403){
                    CustomErrorToast("Permission Denied!")
                }
                else{
                    CustomErrorToast("Error fetching tests, Please try again.")
                }
            })
    }

    useEffect(()=>{
        getAllCandidates();
        if(company){
            document.title  = "Candidates List - " + company.basicTitle
            return () => {
                document.title = company.basicTitle
            }
        }
    }, [company])

    let filteredList  = (candidates && candidates.filter(c=>getCandidateFullName(c).toLowerCase().includes(keyword.toLowerCase()))) || [];
    return (<PageLayout height={"100%"}>
        {showCSV ? <SendTestsCSV refreshCandidates={getAllCandidates} setShow={setShowCSV}/> : ""}
        <div style={{
            justifyContent: "top", display: "flex",height:"100%", width: "100%", flexDirection: "column", padding: "30px"
        }}
             justify={"top"}>
            <FlexBox style={{width:"100%"}} justify={"space-between"}>
                <h3 style={{color: "#364454", fontWeight: "700", fontSize: "20px"}}>Candidates List</h3>
                <SearchBar justify={"end"} value={keyword} setValue={e=>setKeyword(e.target.value)} placeHolder={"Search by Candidate Name"}/>
                <Button hidden={!sentTestsQuota} onClick={()=>setShowCSV(!showCSV)}>Send Tests - CSV</Button>
            </FlexBox>
            <br/>
            <div className={"table-wrapper"}>
                <Table id={"candidatesTable"}>
                    <TableHead columns={["", "Name", "E-Mail", "Last Modified","","",""]}/>
                    <TableBody setCandidates={setCandidates} candidates={filteredList} loader={loader} keyword={keyword}/>
                </Table>
            </div>
            <div style={{marginTop:"10px", fontSize:"12px", textAlign:"left"}}>{!filteredList ? 0 : filteredList.length} Candidates</div>
        </div>
    </PageLayout>)
}




function TableBody({candidates, setCandidates, loader}) {
    return (<tbody>

    <Loader loader={loader}/>
    { !loader && (!candidates || candidates.length == 0)
        && <tr><td style={{verticalAlign:"middle", color:"#bababa", textAlign:"center"}} rowSpan={5} colSpan={6}>No Candidates Found.</td></tr> }
    {candidates.map((c, i) => <CandidateRow key={c.key} setCandidates={setCandidates} candidate={c} index={i}/>)}
    </tbody>);
}


function CandidateRow({candidate, index, setCandidates}) {

    const [sendTest, setSendTest] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete ] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const navigate = useNavigate();

    const deleteCandidate = ()=>{

        const id = CustomLoadingToast("Deleting...")
        axios.delete(API_URL_PREFIX+"/DeleteCandidate.html?id="+candidate.key)
        .then(e=>{
            let data = e.data;
            if(data && !data.err){
                setCandidates(candidates=>candidates.filter(e=>e.key!=candidate.key))
                updateCustomSuccessToast("Deleted",id)
            }
            else{
                updateCustomErrorToast(data.info,id)
            }
            setShowConfirmDelete(false)
        })
        .catch(err=>{
            setShowConfirmDelete(false)
            updateCustomErrorToast("Error, Please try again",id)
            console.log(err)
        })
    }

    return <tr className={"candidate-body"}
               style={{height: "40px", textAlign: "left", verticalAlign: "middle"}}>
        <td style={{width: "50px", textAlign: "center", color:"#bababa"}}>{+index + 1}</td>
        <td><ProfileImage/> <Link className={"candidate-link"} to={APP_URL_PREFIX+"/candidate/" + candidate.key}>&nbsp;{getCandidateFullName(candidate)}</Link>
        </td>
        <td>{candidate.email}</td>
        <td>{new Date(candidate.lastModified).toDateString()}</td>
        <td style={{position: "relative"}}>
            <Button onClick={e=>setSendTest(true)} variant={"outline-primary"}>Send Test Invitation</Button>
        </td>
        <td><Button onClick={() => navigate(APP_URL_PREFIX+"/candidate/" + candidate.key + "/edit")} variant={"outline-success"}><Pencil/> Edit</Button></td>
        <td><Button onClick={() => setShowConfirmDelete(true)} variant={"outline-danger"}><X/> Delete</Button></td>
        {sendTest && <SendTest candidate={candidate} setShow={setSendTest} submitted={submitted} setSubmitted={setSubmitted} />}
        {showConfirmDelete && <ConfirmModal variant={"danger"} show={showConfirmDelete} setShow={setShowConfirmDelete} callback={deleteCandidate} body={"Are you sure you want to delete "+getCandidateFullName(candidate)+"?"} title={"Confirm Delete"} yes={"Delete"} no={"Cancel"} fallback={e=>setShowConfirmDelete(false)}  />}
    </tr>;
}




export default CandidatesList