import React from "react";

const ProfileImage = ({ name = "", src = "" }) => {
    const getInitials = (name) => {
        if (!name || name.trim() === "") {
            return "LT"; // Default initials
        }
        const nameParts = name.trim().split(" ");
        return nameParts
            .filter(part => part) // Filter out empty strings
            .map(part => part[0]?.toUpperCase() || "") // Use optional chaining for safety
            .join("")
            .slice(0, 2); // Limit to two characters
    };
    
    

    if (!name) {
        return (
            <img width={"40px"} height={"40px"} style={{borderRadius:"50%"}} src={"https://cdn.pixabay.com/photo/2017/02/23/13/05/avatar-2092113_960_720.png"}  />
        );
    }

    const initials = getInitials(name);

    return (
        <div
            style={{
                width: "56px",
                height: "56px",
                borderRadius: "50%",
                background: "linear-gradient(270deg, #784DF4 0%, #582ECF 100%)", // Gradient background
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
            }}
            title={name}
        >
            <span
                style={{
                    fontFamily: "Inter",
                    fontSize: "22px",
                    fontWeight: 700,
                    lineHeight: "26.63px",
                    textAlign: "left",
                    textUnderlinePosition: "from-font",
                    textDecorationSkipInk: "none",
                    color: "#FFFFFF", // White text
                }}
            >
                {initials}
            </span>
        </div>
    );
};

export default ProfileImage;
