import {
    CustomLoadingToast,
    updateCustomErrorToast,
    updateCustomSuccessToast
} from "../../../components/utilities/CustomToast";
import axios from "axios";
import {API_URL_PREFIX} from "../../../utilities/Constants";

export const resendTest = (testKey, candidateKey) => {
    const id = CustomLoadingToast("Sending...")
    return axios.post(API_URL_PREFIX+"/ResendQuizToCandidate.html?quizId",{testKey:testKey, candidateKey:candidateKey})
        .then(e => {
            let data = e.data;
            if (data && !data.err) {
                updateCustomSuccessToast("Sent.", id)
            } else {
                updateCustomErrorToast(data.info, id)
                throw new Error(data.info)
            }
        })
        .catch(err => {
            updateCustomErrorToast("Error, Please try again", id)
            console.log(err)
            throw err
        })
}

export const resendResults = (testKey, candidateKey) => {
    const id = CustomLoadingToast("Resending...")
    axios.post(API_URL_PREFIX+"/ResendResultsToRecruiter.html?quizId",{testKey:testKey, candidateKey:candidateKey})
        .then(e => {
            let data = e.data;
            if (data && !data.err) {
                updateCustomSuccessToast("Resent.", id)
            } else {
                updateCustomErrorToast(data.info, id)
            }
        })
        .catch(err => {
            updateCustomErrorToast("Error, Please try again", id)
            console.log(err)
        })
}