import './NavBar.css'
import FlexBox from "../utilities/FlexBox";
import ProfileImage from "../profile/ProfileImage";
import SearchBar from "../search/SearchBar";
import {useContext,useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    getCandidateFullName,
    getPlanName,
    isExpiryInFuture
} from "../../utilities/Utilities";
import CompanyTeamContext from "../../CompanyTeamContext";
import axios from "axios";
import {
    CustomErrorToast, CustomLoadingToast, CustomToastContainer, updateCustomErrorToast, updateCustomSuccessToast
} from "../utilities/CustomToast";
import RoundedLabel from "../utilities/RoundedLabel";
import ModalBackground from "../utilities/modal/ModalBackground";
import Divider from "../utilities/Divider";
import {
    CaretDownFill, CaretUpFill
} from "react-bootstrap-icons";
import InputText from "../utilities/InputText";
import {Button} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {API_URL_PREFIX, APP_URL_PREFIX} from "../../utilities/Constants";
import ModalHeader from "../utilities/modal/ModalHeader";
const NavBar = () => {
    const company = useContext(CompanyTeamContext) || {}
    
    const [showSettings, setShowSettings] = useState(false)
    const [showCompanySettings, setShowCompanySettings] = useState(false)
    const navigate = useNavigate()

    const logout = () => {
        const id = CustomLoadingToast("Processing")
        axios.post(API_URL_PREFIX+"/LiteLogout.html")
            .then(e => {
                let data = e.data;
                if (data && data.result == true) {
                    updateCustomSuccessToast("Logged out!", id)
                    if (company.refresh) company.refresh();

                    navigate("/users/login")
                } else {
                    CustomErrorToast("Try again.")
                }
            })
            .catch(err => {
                console.log(err)
            })
    }


    let testsQuota;
    if(!company.selectedLitePlan){
        testsQuota = "Select Plan to Start Sending Tests";
    }
    else{
        // no tests left
        if(company.sentTestsQuota == 0){
            // will it be renewed?
            if(company.isPlanAutoRenewable){
                testsQuota = "No tests left to send, the quota will be renewed at "+new Date(company.selectedLitePlanExpireDate).toLocaleDateString()
            }
            else{
                testsQuota = "No tests left to send"
            }
            testsQuota = <div style={{borderRadius:"5px",border:"3px solid #ebebeb",padding:"0px 30px",background:"#ebebeb",height:"max-content",}}>{testsQuota}</div>;
        }
        else {
            if(company.isPlanAutoRenewable){
                testsQuota = <> <RoundedLabel style={{height: "40px", width: "40px", fontWeight: "bold"}}
                                              value={company.sentTestsQuota}/>&nbsp;&nbsp;Tests left to send until {new Date(company.selectedLitePlanExpireDate).toLocaleDateString()}</>
            }
            else{
                testsQuota = <> <RoundedLabel style={{height: "40px", width: "40px", fontWeight: "bold"}}
                                              value={company.sentTestsQuota}/>&nbsp;&nbsp;Tests left to send.</>
            }
        }
    }

    return (<div style={{}} className={"navbar"}>

        <FlexBox justify={"start"} style={{height:"100%",width:"33.333%"}}>
            <Search company={company}/>


        </FlexBox>
        <FlexBox justify={"center"} style={{textAlign: "center",height:"100%",width:"33.333%"}}>

            {testsQuota}

        </FlexBox>
        <FlexBox className={"buttons"}  style={{height:"100%",width:"33.333%"}}>
            <FlexBox align={"space-between"} justify={"end"} style={{width: "100%"}}>

            <ProfileImage name={`${company.loggedLiteUser?.firstName || "L"} ${company.loggedLiteUser?.lastName || "T"}`}
/>
                <FlexBox onClick={e => setShowSettings(!showSettings)} className={"profile-holder hover-blue"}
                         justify={"center"} style={{margin: "5px 5px"}}
                         direction={"column"}>
                    <FlexBox align={"center"} style={{fontWeight: "600", marginLeft: "20px"}}>{company.name}&nbsp;&nbsp;

                        {showSettings ? <CaretUpFill style={{color: "#000"}}/> :
                            <CaretDownFill style={{color: "#000"}}/>}

                    </FlexBox>
                    <div hidden={!showSettings} style={{position: "relative", width: 0, height: 0}}>

                        <FlexBox direction={"column"} style={{
                            position: "absolute",
                            top: 10,
                            right: 0,
                            background: "#fff",
                            zIndex: 1,
                            width: "180px",
                            height: "max-content",
                            border: "1px solid #ebebeb",
                            filter: "drop-shadow(3px 0px 5px rgba(0, 0, 0, 0.25))",
                            color: "#000"
                        }}>

                            <div onClick={e => setShowCompanySettings(true)} style={{
                                cursor: "pointer", padding: "15px", borderBottom: "1px solid #ebebeb"
                            }}>Company Settings
                            </div>
                            <div style={{cursor: "pointer", padding: "15px", borderBottom: "1px solid #ebebeb"}}
                                 onClick={logout}>Logout
                            </div>

                        </FlexBox>
                    </div>
                </FlexBox>
            </FlexBox>
        </FlexBox>
        {<CustomToastContainer />}
        {showCompanySettings && <CompanySettings setShow={setShowCompanySettings}/>}
    </div>)
}

const Search = ({company}) => {
    const [search, setSearch] = useState("")
    const [candidates, setCandidates] = useState()
    const [loading, setLoading] = useState(false)
    const [showResults, setShowResults] = useState(false)
    const ref = useRef(null)
    const refDiv = useRef(null)
    const navigation = useNavigate()

    useEffect(() => {
        searchAction("", true)
    }, [company])

    const mouseOutHandle = e=>{
        setShowResults(showResults =>{
            if(showResults){
                if(ref.current && !ref.current.contains(e.target) && refDiv.current && !refDiv.current.contains(e.target)){
                    return false;
                }
            }
            return showResults;
        })
    }

    useEffect(()=>{
        document.addEventListener('mousedown',mouseOutHandle)
        return (()=>{
            document.removeEventListener('mousedown',mouseOutHandle)
        })
    },[])


    const searchAction = (e, refresh = false) => {

        if (company && company.selectedLitePlan && (candidates == null || refresh)) {
            setLoading(true)
            axios.get(API_URL_PREFIX+"/GetAllCandidates.html")
                .then(e => {
                    setLoading(false)
                    let data = e.data;
                    if (data && !data.err) {
                        setCandidates(data)
                    } else {
                        CustomErrorToast("Error searching candidates")
                    }
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                    CustomErrorToast("Error searching candidates")
                })
        }
        setSearch(e)
        if(e == "") setShowResults(false)
        if(!refresh && e) setShowResults(true)
    
    }

    const getFilteredList = ()=>{
        return candidates ? candidates.filter(
            c=>getCandidateFullName(c).toLowerCase().includes(search.toLowerCase()))
            .slice(0,5)
            .map(e=>(<CandidateSearchResult candidate={e} /> )) : [];
    }

    let filteredList = useMemo(getFilteredList, [search, candidates, company])

    return (
        <div style={{position:"relative"}}>
            <SearchBar style={{background:"white", borderRadius:"30px",border:"none"}} placeHolder={"Search Candidates"} onBlur={e=>setShowResults(false)} refVar={refDiv} value={search} setValue={e => searchAction(e.target.value, false)} width={"450px"} loading={loading}/>
            {showResults &&
                <div  ref={ref} style={{textAlign:"center",border:"1px solid #ebebeb",width:"360px", background:"white",height:"max-content",position:"absolute",zIndex:400}}>
                    {(search.length > 0 && candidates) ? <> {filteredList}
                            {filteredList.length > 0 ?
                            <Button variant={"link"} onClick={e=>{
                            setShowResults(false)
                                navigation(APP_URL_PREFIX+"/candidate/list?kw="+search)
                            }
                            }>Show all results for "<b>{search}</b>"</Button> : <div style={{color:"#bababa",padding:"20px"}}>No Candidates were found.</div>}
                        </> :
                        <div style={{color:"#bababa",padding:"20px"}}>No Candidates were found.</div>}
                </div>
            }
        </div>
    )
}

const CandidateSearchResult = ({candidate}) =>{

    return (
        <FlexBox justify={"start"} style={{border:"1px solid #ebebeb",padding:"10px", textAlign:"left"}}>
            <ProfileImage/>
            <div style={{width:"10px"}}></div>
            <FlexBox direction={"column"} justify={"start"} align={"start"}>
                <Link className={"candidate-link"} to={APP_URL_PREFIX+"/candidate/" + candidate.key}>&nbsp;{getCandidateFullName(candidate)}</Link>
                <div>&nbsp;{candidate.email}</div>
            </FlexBox>

        </FlexBox>
    )
}

const CompanySettings = ({setShow}) => {
    const company = useContext(CompanyTeamContext) || {};
    const [name, setName] = useState("")

    const [currentPass, setCurrentPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [confirmPass, setConfirmPass] = useState("")

    const save = () => {
        if (!name) {
            CustomErrorToast("Company name can not be empty")
            return;
        }
        if (currentPass && (!newPass || !confirmPass)) {
            CustomErrorToast("To change your password please fill both the new password field and the confirmation")
            return;
        }
        if (currentPass && (newPass != confirmPass)) {
            CustomErrorToast("Password confirmation do not match")
            return;
        }
        const id = CustomLoadingToast("Saving...")
        axios.put(API_URL_PREFIX+"/UpdateLiteCompany.html", {name, currentPass, newPass})
            .then(e => {
                let data = e.data;
                if (data && data.result) {
                    updateCustomSuccessToast("Success!", id)
                    if (company.refresh) company.refresh();
                } else if (data && data.err) {
                    updateCustomErrorToast(data.info, id)
                } else {
                    updateCustomErrorToast("Please try again", id)
                }
            })
            .catch(err => {
                updateCustomErrorToast("Please try again", id)
            })
    }


    useEffect(() => {
        if (company) {
            setName(company.name)
        }
    }, [company])

    return (<ModalBackground>
        <FlexBox direction={"column"} justify={"start"} align={"start"}
                 style={{width: "370px", minHeight: "420px", background: "white"}}>
            <ModalHeader title={"Company Settings"} setShow={setShow} />


            <FlexBox direction={"column"} align={"start"} style={{padding: "20px", width: "100%"}}>
                <h4 style={{textAlign:"center", width:"100%"}}>{company.selectedLitePlan? getPlanName(company.selectedLitePlan)  + " Plan" : "No Plan Selected"}</h4>
                {company.selectedLitePlan && company.isPlanAutoRenewable && company.selectedLitePlanExpireDate
                    ?  <h6 style={{textAlign:"center", width:"100%"}}>({"Will be renewed at "+new Date(company.selectedLitePlanExpireDate).toLocaleDateString()})</h6>
                    : (isExpiryInFuture(company)
                    ? <h6 style={{textAlign:"center", width:"100%"}}>({"Will expire at "+new Date(company.selectedLitePlanExpireDate).toLocaleDateString()})</h6>
                    : (company.selectedLitePlanExpireDate ? <h6 style={{textAlign:"center", width:"100%"}}>({"Expired at "+new Date(company.selectedLitePlanExpireDate).toLocaleDateString()})</h6>
                            : "")
                    )
                }
                <InputText disabled={(company?.isBusinessLiteCompany && company?.loggedLiteUser?.role != 1)} type={"text"} setField={e => setName(e.target.value)} field={name} width={"330px"}
                           label={"Company Name"}/>

                <InputText type={"text"} disabled={true} field={company.liteEmail || company.loggedLiteUser?.email} width={"330px"} label={"Email"}/>

                {company.loggedLiteUser
                    ? <InputText type={"text"} disabled={true} field={company.loggedLiteUser.firstName+" "+company.loggedLiteUser.lastName} width={"330px"} label={"User"}/>
                    : ""

                }

                <br/>

                <div style={{
                    width: "100%",
                    marginBottom: "5px",
                    textAlign: "center",
                    fontWeight: "bold",
                    textDecoration: "underline"
                }}>Change Password
                </div>
                <InputText width={"330px"} type={"password"} setField={e => setCurrentPass(e.target.value)} field={currentPass}
                           label={"To change your password please enter the current"}/>
                <Divider/>
                <InputText width={"330px"} type={"password"} setField={e => setNewPass(e.target.value)} field={newPass}
                           label={"New Password"}/>
                <InputText width={"330px"} type={"password"} setField={e => setConfirmPass(e.target.value)} field={confirmPass}
                           label={"Confirm New Password"}/>
                <div style={{fontSize: "12px"}}>* Leave empty to change only the company name.</div>
            </FlexBox>
            <Button onClick={save} style={{margin: "0px 0px 20px 20px", float: "right"}}>Save Changes</Button>
        </FlexBox>

    </ModalBackground>);
}

export default NavBar