export const TEST_BUILDER_PRIVILEGE = "TEST_BUILDER_PRIVILEGE";
export const TEST_PREVIEW_PRIVILEGE = "TEST_PREVIEW_PRIVILEGE";
export const TEST_REVIEW_PRIVILEGE = "TEST_REVIEW_PRIVILEGE";

export const DELIMETER_BETWEEN_QUESTIONS = "__#__";

export const ADMIN_PREFIX = "/admin";
export const DEVELOPER_PREFIX = "/developer";
export const RECRUITER_PREFIX = "/recruiter";
export const AUTHENTICATED_PREFIX = "/auth";
export const PUBLIC_PREFIX = "/public";

export const PASS_THRESHOLD = 60;

export const API_VERSION = "/v1";

export const FULL_ADMIN_PREFIX = ADMIN_PREFIX + API_VERSION;
export const FULL_DEVELOPER_PREFIX = DEVELOPER_PREFIX + API_VERSION;
export const FULL_RECRUITER_PREFIX = RECRUITER_PREFIX + API_VERSION;
export const FULL_AUTHENTICATED_PREFIX = AUTHENTICATED_PREFIX + API_VERSION;
export const FULL_PUBLIC_PREFIX = "";

export const APP_URL_PREFIX = ""
export const API_URL_PREFIX = ""


export const ENTRY_PLAN = "ENTRY_PLAN";
export const PRO_PLAN = "PRO_PLAN";
export const ENTERPRISE_PLAN = "ENTERPRISE_PLAN";

export const MONTHLY_TIMEFRAME = "MONTHLY";
export const ANNUAL_TIMEFRAME = "ANNUAL";

export const ENTRY_PLAN_TESTS = 5;
export const PRO_PLAN_TESTS = 40;
export const ENTERPRISE_PLAN_TESTS = 200;

export const ENTRY_PLAN_MONTHLY = 50;
export const PRO_PLAN_MONTHLY = 200;
export const ENTERPRISE_PLAN_MONTHLY = 450;

export const ENTRY_PLAN_ANNUAL = 35;
export const PRO_PLAN_ANNUAL = 150;
export const ENTERPRISE_PLAN_ANNUAL = 300;

// LIVE ----------------------------
// MONTHLY SUB
export const ENTRY_PLAN_MONTHLY_SUBSCRIPTION_ID = "P-9U236931J6642580PMO6T6WY";
export const PRO_PLAN_MONTHLY_SUBSCRIPTION_ID= "P-24T77376B8974033YMO6UAEQ";
export const ENTERPRISE_PLAN_MONTHLY_SUBSCRIPTION_ID = "P-4NK2767704395432XMO6UAXY";

// ANNUAL SUB
export const ENTRY_PLAN_ANNUAL_SUBSCRIPTION_ID = "P-4F880721SH8779918MO6UHIQ";
export const PRO_PLAN_ANNUAL_SUBSCRIPTION_ID = "P-1ME909265E6821825MO6UH2Y";
export const ENTERPRISE_PLAN_ANNUAL_SUBSCRIPTION_ID = "P-6V387190J1489661FMO6UGSA";
// LIVE ----------------------------


/*// SANDBOX ----------------------------
// MONTHLY SUB
export const ENTRY_PLAN_MONTHLY_SUBSCRIPTION_ID = "P-48W69041823319132MO6VKNQ";
export const PRO_PLAN_MONTHLY_SUBSCRIPTION_ID= "P-48W69041823319132MO6VKNQ";
export const ENTERPRISE_PLAN_MONTHLY_SUBSCRIPTION_ID = "P-48W69041823319132MO6VKNQ";

// ANNUAL SUB
export const ENTRY_PLAN_ANNUAL_SUBSCRIPTION_ID = "P-8XV36894N01699513MO6VK7I";
export const PRO_PLAN_ANNUAL_SUBSCRIPTION_ID = "P-8XV36894N01699513MO6VK7I";
export const ENTERPRISE_PLAN_ANNUAL_SUBSCRIPTION_ID = "P-8XV36894N01699513MO6VK7I";
// SANDBOX ----------------------------*/


export const LIVE_CLIENT_ID = "AbsBMOXlwlaZVsCS8q4jZavklzEgAJwpfJDBHqJkbqqGmjNVxersr7J4dFm7UMKaVC-TyN3SfNNVmrPB"
//export const SANDBOX_CLIENT_ID = "AeCWForr_bAAlEcTLSwS6NcSYE9f6768wdouiIBvDAbByNTWJhNjb_ghVmWGqDfZpSwYvJAFz0EJ7am3"
//export const SANDBOX_CLIENT_ID = "AS_avalF3-xH1p3sDeRt_vApTvs5IP7lXwxJ-9tXJZaD1nr9TnSp-V2AU72gZqddoGhVqgxzg2Ygut3t"


export const IFRAME_DOMAIN = "https://lugotest-hrd.appspot.com";//https://question-bank-beta-a7neof6f-hsncks-dot-lugotest-hrd.appspot.com";//"https://question-bank-beta-9-17-20-dot-lugotest-hrd.appspot.com";//";//

